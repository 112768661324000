import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as gasActionCreators from "../../actions/newGasContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import { i18n } from "../../config";

import CheckboxField from "../form-fields/CheckboxField";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import AccessTariffSelectField from "../form-fields/AccessTariffSelectField";
import ComerOriginSelectField from "../form-fields/ComerOriginSelectField";
import GasProductSelectField from "../form-fields/GasProductSelectField";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import Address from "../Address";

import Settings from "../../settings";

import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {CircularProgress, Button, Box, Typography, Grid, Paper, Divider, Slider} from "@material-ui/core"
import WhatshotIcon from '@material-ui/icons/Whatshot';
import RadioGroupField from "../form-fields/RadioGroupField";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    electricity: state.newElectricityContract,
    ...state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(gasActionCreators, dispatch);
}

const FormAboutGasContract = (props) => {
  const [comerOriginEnabled, setComerOriginEnabled] = useState(false);

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  const handleSubmit = async (values) => {
    props.submitAboutContractData(values);

    if (props.onSubmit) {
      await props.onSubmit();
    }
  };

  const handleCUPSUpdate = (values) => {
    const value = values.cups;
    if (value) {
      props.storeGasCUPSData(value);
      if (cupsValid(value)) {
        props.validateGasCUPSAvailability(values, true);
      }
    }
  };

  const handleAvailableComersUpdate = () => {
    setComerOriginEnabled(true);
  };

  const handleComerOriginUpdate = async (value) => {
    props.setComerOrigin(value);
  };

  const handleIncludeGasUpdate = async (value) => {
    props.setIncludeGas(value);
  };

  const handleAccessTariffSelectUpdate = async (value) => {
    props.setAccessTariff(value);
    let accessTariff = value;
    props.fetchAvailableGasProducts(props.cups, accessTariff);
  };

  const handleChangeOwnerUpdate = async (value) => {
    props.setChangeOwner(value);
  };

  const handleInvoiceUpdate = async (value) => {
    props.setInvoice(value);
  }

  const theme = useTheme();
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box m={2} display={"flex"} style={{marginLeft: 0}}>
          <WhatshotIcon fontSize="large" color="secondary" style={{padding: 10}}/>
          <Typography variant="h4" style={{alignSelf: "center"}}>{i18n.t('common:text.contractation_about_gas_contract')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          includeGas: _.get(Settings, "newDualContract.gasIsMandatory", false)
            ? true
            : props.includeGas,
          cups: props.cups,
          changeOwner: props.changeOwner,
          accessTariff: props.accessTariff,
          selectedProduct: props.selectedProduct,
          comerOrigin: props.comerOrigin,
          invoice: props.invoice,
        }}
        keepDirtyOnReinitialize={true}
        validate={(values) => {
          console.log(values);
          const errors = {};

          if (values.includeGas) {
            if (!values.cups) {
              errors.cups = i18n.t('common:text.required_field');
            } else if (!cupsValid(values.cups)) {
              errors.cups = i18n.t('common:text.contractation_cups_not_valid');
            }

            if (!values.accessTariff) {
              errors.accessTariff = i18n.t('common:text.required_field');
            }

            if (!values.selectedProduct) {
              errors.selectedProduct = i18n.t('common:text.required_field');
            }

            const fileErrors = validateFileUpload(values.invoice, {
              min: 1,
              minMessage: i18n.t('common:text.multiupload_validation_min'),
            });
            if (fileErrors) {
              _.set(errors, "invoice", fileErrors);
            }
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Paper variant="outlined" style={{paddingLeft: 15, marginBottom: 15}}>
              <Field
                name="includeGas"
                label={i18n.t('common:text.contractation_include_gas')}
                component={CheckboxField}
                onUpdate={handleIncludeGasUpdate}
              />
            </Paper>
            {values.includeGas && (
              <>
                <Typography variant="h6" color="primary">
                  {i18n.t('common:text.contractation_current_gas_contract_data')}
                </Typography>
                <Typography variant="body2" style={{marginBottom: 15}}>
                  {i18n.t('common:text.contractacion_about_home_info')}
                </Typography>
                <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {i18n.t('common:text.contractation_cups_title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={4}>
                    <Field
                      name="cups"
                      component={CUPSField}
                      label={i18n.t('common:text.contractation_cups_gas')}
                      onUpdate={(value) =>
                        handleCUPSUpdate({ ...values, cups: value })
                      }
                      style={{width: "100%"}}
                      cupsAvailability={props.cupsAvailability}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="helper-text">{i18n.t('common:text.contractation_cups_gas_helper')}</div>
                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                  </Grid>
                  {!_.isEmpty(props.electricity.address) && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {i18n.t('common:text.contracts_direction')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Address readonly value={props.electricity.address} />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
                    <Paper elevation={3} style={{padding: 15}}>
                      <Typography variant="h6">
                        { _.get(Settings, 'newContract.nameInChangeOwnerQuestion', false) ?
                          i18n.t("common:text.contractation_change_owner_question_named", {owner: props.name + " " + props.surName1 + " " + props.surName2})
                          : i18n.t("common:text.contractation_change_owner_question")
                        }
                      </Typography>
                      <Field
                        name="changeOwner"
                        component={RadioGroupField}
                        radioElements={
                          [
                            {label: i18n.t("common:text.generic_yes"), val: "changeOwner", style: {width: "25%"}},
                            {label: i18n.t("common:text.generic_no"), val: "dontChangeOwner", style: {width: "25%"}}
                          ]
                        }
                        row
                      />
                    </Paper>
                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                  </Grid>

                  {(!props.sipsData || !props.accessTariff) && props.cups &&
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {i18n.t('common:text.contract_title')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6} style={{marginBottom: 10}}>
                        <Field
                          name="accessTariff"
                          component={AccessTariffSelectField}
                          gas={true}
                          initialTariff={props.accessTariff}
                          onSelectUpdate={handleAccessTariffSelectUpdate}
                        />
                      </Grid>
                    </>
                  }
                  {comerOriginEnabled &&
                    <Grid item xs={12} sm={8} md={6} style={{marginBottom: 10}}>
                      <Field
                        name="comerOrigin"
                        component={ComerOriginSelectField}
                        onAvailableComersUpdate={handleAvailableComersUpdate}
                        onUpdate={handleComerOriginUpdate}
                        gas={true}
                      />
                    </Grid>
                  }
                  <Grid item xs={12} style={{marginBottom: 15}}>
                    <Divider style={{marginBottom: 10, marginTop: 10}}/>
                    <Typography variant="h6">
                      {i18n.t('common:text.contractation_last_gas_invoice')}
                    </Typography>
                    <Paper style={{padding: 15}} variant="outlined">
                      <Field
                        name="invoice"
                        component={FileUploadField}
                        min={1}
                        max={3}
                        label={i18n.t('common:text.contractation_last_invoice')}
                        hint={i18n.t('common:text.contractation_last_gas_invoice_helper')}
                        anotherLabel={i18n.t('common:text.contractation_last_invoice_add')}
                        removeLabel={i18n.t('common:text.remove')}
                        onUpdate={handleInvoiceUpdate}
                      />
                    </Paper>
                  </Grid>
                </Box>
                <Typography variant="h6" color="primary">
                  {i18n.t('common:text.contractation_new_pricelist')}
                </Typography>
                <Typography variant="body2" style={{marginBottom: 15}}>
                  {i18n.t('common:text.contractation_current_pressure_warning')}
                </Typography>
                <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0}>
                  {props.accessTariff &&
                    <Field
                      name="selectedProduct"
                      component={GasProductSelectField}
                    />
                  }
                </Box>
              </>
            )}
            <div style={{ marginTop: 15 }}>
              <Button
                variant={'text'}
                onClick={handleGoBack}
                style={{ marginRight: 12 }}
                disabled={submitting}
              >
                {i18n.t('common:text.contractation_previous')}
              </Button>
              <Button
                type="submit"
                color={'primary'}
                variant={'contained'}
                disabled={submitting || (props.cupsAvailability.invalid !== null)}
              >
                {submitting ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormAboutGasContract);
