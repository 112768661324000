import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware  from 'redux-thunk';
import rootReducer  from '../reducers';
import { createLogger } from 'redux-logger';

import * as Sentry from '@sentry/react';

import Settings from '../settings';

// Added ignore to Sentry console logs as they are unreadable and useless:
// https://github.com/getsentry/sentry-react-native/issues/794#issuecomment-908189765
if (Settings.sentry && Settings.sentry.dsn) {
    Sentry.init({
        ...Settings.sentry,
        integrations: [
            new Sentry.Integrations.Breadcrumbs({
                console: false
            })
        ],
        release: `oficina-virtual@${import.meta.env.NPM_PACKAGE_VERSION}` });
}

const debugware = [];
if (import.meta.env.DEV) {
    debugware.push(createLogger({
        collapsed: true,
    }));
}

export const configureStore = (initialState) => {

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                thunkMiddleware,
                ...debugware
            )
        ),
    );

    return store;
}

export const store = configureStore();
