import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoupons } from "../actions/coupons";

import { PublicityBanner } from "./PublicityBanner";
import { Box, useMediaQuery } from "@material-ui/core";

export const CouponsView = props => {
  const matches = useMediaQuery('(min-width:1200px)'); // Moltíssimes gràcies Nico per aquesta merda
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const coupons = useSelector(state => state.coupons.data);

  useEffect(() => {
    if (coupons === null && token) {
      dispatch(fetchCoupons(token));
    }
  }, [token]); 

  return (coupons ? 
    <Box style={{margin: `30px 7vw ${matches ? '-15px' : '30px'} 7vw`}}>
      {coupons.map((coupon, idx) => <PublicityBanner {...coupon} key={idx}/>)}
    </Box> : <div></div>);
};
