import _ from "lodash";
import queryString from "query-string";
import { history } from '../config';
import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGIN_USER_REQUEST,
    LOGIN_OPENID_SUCCESS,
    LOGIN_OPENID_FAILURE,
    LOGIN_OPENID_REQUEST,
    LOGIN_CLIENTID_SUCCESS,
    LOGIN_CLIENTID_FAILURE,
    LOGIN_CLIENTID_REQUEST,
    LOGIN_REFRESH_REQUEST,
    LOGIN_REFRESH_SUCCESS,
    LOGIN_REFRESH_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    LOGOUT_REQUEST,
    REGISTER_USER_FAILURE,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    RECOVER_USER_REQUEST,
    RECOVER_USER_FAILURE,
    RECEIVE_REGISTER_SPECS,
    RECEIVE_REGISTER_SPECS_KO,
    FETCH_REGISTER_SPECS_REQUEST,
} from '../constants/index';
import {
    login_url,
    connect_url,
    home_url,
    contraction_url
} from '../constants/i18n';
import { i18n } from "../config";

import jwtDecode from 'jwt-decode';

import {
    define_token,
    undefine_token,
    get_token,
    openid_login,
    openid_logout,
    refresh_token,
    get_token_by_client_id,
    create_user,
    ask_recover,
    data_fetch_api_resource,
    data_update_api_resource
} from '../utils/http_functions';

import {
    store_openid_token,
    retrieve_openid_token,
    remove_openid_token,
    clearKey,
    verifyState,
    getNonce,
    clearNonce,
} from '../utils/auth';

import {
    parseJSON,
    generateHash
} from '../utils/misc';

import {
    registerCoupon
} from './coupons';

import Settings, {
    features
} from '../settings';
import {getLangByToken} from "../i18n";

export function loginUserSuccess(token) {
    define_token(token);
    return {
        type: LOGIN_USER_SUCCESS,
        payload: {
            token,
        },
    };
}

export function loginUserFailure(error) {
    undefine_token();
    return {
        type: LOGIN_USER_FAILURE,
        payload: {
            status: (error && error.status) || "403",
            statusText: (error && error.statusText) || i18n.t('common:text.login_view_message_error_login'),
            statusType: "error",
        },
    };
}

export function loginUserRequest() {
    return {
        type: LOGIN_USER_REQUEST,
    };
}

/* Login OpenID Connect */

export function loginOpenIdSuccess(token, openIdToken) {
    clearNonce();
    define_token(token);
    store_openid_token(openIdToken);
    
    return {
        type: LOGIN_OPENID_SUCCESS,
        payload: {
            token,
            openIdToken
        },
    };
}

export function loginOpenIdFailure(error) {
    clearNonce();
    undefine_token();
    remove_openid_token();
    return {
        type: LOGIN_OPENID_FAILURE,
        payload: {
            status: (error && error.status) || "403",
            statusText: (error && error.statusText) ||  i18n.t('common:text.login_view_message_error_login'),
            statusType: "error",
        },
    };
}

export function loginOpenIdRequest() {
    return {
        type: LOGIN_OPENID_REQUEST,
    };
}

/* ClientId Login */

export function loginClientIdSuccess(token) {
    return (dispatch) => {
        define_token(token);
        dispatch(redirectToRoute(contraction_url));
        
        return {
            type: LOGIN_CLIENTID_SUCCESS,
            payload: {
                token,
            },
        };
    }
}

export function loginClientIdFailure(error) {
    undefine_token();
    return {
        type: LOGIN_CLIENTID_FAILURE,
        payload: {
            status: (error && error.status) || "403",
            statusText: (error && error.statusText) || i18n.t('common:text.login_view_message_error_login'),
            statusType: "error",
        },
    };
}

export function loginClientIdRequest() {
    return {
        type: LOGIN_CLIENTID_REQUEST,
    };
}

/* Refresh Session */

export function loginRefreshRequest() {
    return {
        type: LOGIN_REFRESH_REQUEST,
    };
}

export function loginRefreshSuccess(token) {
    define_token(token);
    
    return {
        type: LOGIN_REFRESH_SUCCESS,
        payload: {
            token,
        },
    };
}

export function loginRefreshFailure(error) {
    undefine_token();
    return {
        type: LOGIN_REFRESH_FAILURE,
        payload: {
            status: error.status || "403",
            statusText: error.statusText || i18n.t('common:text.login_view_message_error_login'),
            statusType: "error",
        },
    };
}


/* Logout */

export function logoutSuccess() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

export function logoutFailure(error) {
    return {
        type: LOGOUT_FAILURE,
        payload: {
            status: error.status || "403",
            statusText: error.statusText || i18n.t('common:text.logout_view_message_error_logout'),
            statusType: "error",
        },
    };
}

export function logoutRequest() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function logoutAndRedirect() {
    return dispatch => {
        dispatch(logoutRequest());
        undefine_token();
        if (features.oidc) {
            const openid_token = retrieve_openid_token();
            if (openid_token) {
                remove_openid_token();
                openid_logout(openid_token);
            } else {
                dispatch(logoutSuccess());
                history.push(connect_url);
            }
        } else {
            dispatch(logoutSuccess());
            history.push(login_url);
        }
    };
}

export function redirectToRoute(route) {
    return () => {
        if (route.indexOf('http') === 0) {
            window.location.href = route;
        } else {
            history.push(route);
        }
    };
}

export function loginUser(user, password, redirect = home_url) {
    return function(dispatch) {
        dispatch(loginUserRequest());
        return get_token(user, password).then(response => {
            try {
                if (response.data.token) {
                    dispatch(loginUserSuccess(response.data.token));
                    i18n.changeLanguage(getLangByToken(response.data.token)).then(() => {
                        if (response.data.change_password) {
                            history.push(i18n.t('common:url.changePassword'));
                        } else {
                            history.push(redirect);
                        }
                    });
                } else {
                    dispatch(loginUserFailure());
                }
            } catch (e) {
                dispatch(loginUserFailure({
                    status: 403,
                    statusText: 'Invalid token',
                    statusType: "warning",
                }));
            }
        }).catch(error => {
            dispatch(loginUserFailure(error));
        });

    };
}

export function loginOpenId(code, state, redirect = home_url) {
    return dispatch => {
        const originalNonce = getNonce();
        if (!originalNonce) {
            dispatch(loginOpenIdFailure({
                status: 403,
                statusText: 'nonce not present.',
                statusType: "warning",
            }));
        } else {
            verifyState(state)
            .then(state => {
                let qsParameters = state.payload.qs;
                clearKey();
                const nonce = generateHash(originalNonce);
                dispatch(loginOpenIdRequest());
                return openid_login(code, nonce)
                    .then(parseJSON)
                    .then(response => {
                        try {
                            if (response.token) {
                                if ('redirect' in qsParameters) {
                                    redirect = qsParameters.redirect;
                                    delete qsParameters.redirect;
                                }
                                dispatch(loginOpenIdSuccess(response.token, response.openid_token));
                                if (response.new_customer) {
                                    dispatch(redirectToRoute(contraction_url + "?" + queryString.stringify(qsParameters)));
                                } else {   
                                    // dispatch(registerCoupon(state.payload.qs)); // Per quan ho fem genèric o quan vagi a la V2
                                    dispatch(redirectToRoute(redirect + "?" + queryString.stringify(qsParameters)));
                                }
                            } else {
                                dispatch(loginOpenIdFailure());
                            }
                        } catch (e) {
                            dispatch(loginOpenIdFailure({
                                status: 403,
                                statusText: 'Invalid token',
                                statusType: "warning",
                            }));
                        }
                    })
                    .catch(error => {
                        dispatch(loginOpenIdFailure(error));
                    });
            })
            .catch(err => {
                console.log(err);
                dispatch(loginOpenIdFailure({
                    status: 403,
                    statusText: 'Invalid state',
                    statusType: "warning",
                }));
            });
        }
    };
}

export function loginClientId(clientId, secret) {
    return function(dispatch) {
        dispatch(loginClientIdRequest());
        return get_token_by_client_id(clientId, secret)
            .then(parseJSON)
            .then(response => {
                try {
                    if (response.token) {
                        dispatch(loginClientIdSuccess(response.token));
                    } else {
                        dispatch(loginClientIdFailure());
                    }
                } catch (e) {
                    dispatch(loginClientIdFailure({
                        response: {
                            status: 403,
                            statusText: 'Invalid token',
                            statusType: "warning",
                        },
                    }));
                }
            })
            .catch(error => {
                dispatch(loginClientIdFailure(error));
            });
    };
}

export function loginToken(token, redirect = home_url) {
    return function(dispatch) {
        dispatch(loginUserRequest());

        if (token.constructor === String) {
            try {
                dispatch(loginUserSuccess(token));
                i18n.changeLanguage(getLangByToken(token)).then(
                        () => {history.push(redirect);}
                );
            } catch (e) {
                dispatch(loginUserFailure({
                    response: {
                        status: 403,
                        statusText: 'Invalid token',
                        statusType: "warning",
                    },
                }));
            }
        } else {
          dispatch(loginUserFailure({
              response: {
                  status: 403,
                  statusText: 'Invalid token',
                  statusType: "warning",
              },
          }));
        }
    };
}

export const loginRefresh = (redirect) => {
    return async (dispatch) => {
        dispatch(loginRefreshRequest());
        try {
            const response = await refresh_token();
            dispatch(loginRefreshSuccess(response.data.token));

            if (response.data.change_password) {
                history.push(i18n.t('common:url.changePassword'));
            } else if (redirect) {
                history.push(redirect);
            }
        } catch (e) {
            dispatch(loginRefreshFailure(e));
        }
    };
}


/********************
   CHANGE PASSWORD 
********************/

export function changePasswordSuccess(token) {
    define_token(token);
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: {
            token,
        },
    };
}

export function changePasswordFailure(error) {
    undefine_token();
    return {
        type: CHANGE_PASSWORD_FAILURE,
        payload: {
            status: (error.status === undefined) ? "403" : error.status,
            statusText: (error.statusText === undefined) ? i18n.t('common:text.change_password_view_message_error_changing') : error.statusText,
            statusType: "error",
        },
    };
}

export function changePasswordRequest() {
    return {
        type: CHANGE_PASSWORD_REQUEST,
    };
}

export function changePassword(currentPassword, newPassword, redirect = home_url) {
    return function(dispatch) {
        dispatch(changePasswordRequest());
        return data_update_api_resource(currentPassword, newPassword)
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(changePasswordSuccess(response.token));
                    history.push(redirect);
                } catch (e) {
                    dispatch(changePasswordFailure({
                        response: {
                            status: 403,
                            statusText: 'Invalid token',
                            statusType: "warning",
                        },
                    }));
                }
            })
            .catch(error => {
                dispatch(changePasswordFailure(error));
            });
    };
}






export function registerUserRequest() {
    return {
        type: REGISTER_USER_REQUEST,
    };
}

export function registerUserSuccess(token) {
    define_token(token);
    return {
        type: REGISTER_USER_SUCCESS,
        payload: {
            token,
        },
    };
}

export function registerUserFailure(error) {
    undefine_token();
    return {
        type: REGISTER_USER_FAILURE,
        payload: {
            status: error.response.status,
            statusText: error.response.statusText,
            statusType: "error",
        },
    };
}

export function registerUser(email, password) {
    return function(dispatch) {
        dispatch(registerUserRequest());
        return create_user(email, password)
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(registerUserSuccess(response.token));
                    history.push(home_url);
                } catch (e) {
                    dispatch(registerUserFailure({
                        response: {
                            status: 403,
                            statusText: 'Invalid token',
                            statusType: "warning",
                        },
                    }));
                }
            })
            .catch(error => {
                dispatch(registerUserFailure({
                    response: {
                        statusType: "warning",
                        status: 403,
                        statusText: error.response.data.message,
                    }
                }));
            });
    };
}

export function recoverUser(email) {
    return function(dispatch) {
        dispatch(recoverUserRequest());
        return ask_recover(email)
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(loginUserSuccess(response.token));
                    history.push('/');
                } catch (e) {
                    alert(e);
                    dispatch(recoverUserFailure({
                        response: {
                            status: 403,
                            statusText: 'Invalid token',
                            statusType: "warning",
                        },
                    }));
                }
            })
            .catch(error => {
                dispatch(recoverUserFailure(error));
            });
    };
}

export function recoverUserRequest() {
    return {
        type: RECOVER_USER_REQUEST,
    };
}

export function recoverUserFailure(error) {
    return {
        type: RECOVER_USER_FAILURE,
        payload: {
            status: (error.status === undefined) ? "" : error.status,
            statusText: (error.statusText === undefined) ? "This service is not available right now. Try it in a few minutes please." : error.statusText,
            statusType: "error",
        },
    };
}










export function receiveRegisterSpecification(data) {
    return {
        type: RECEIVE_REGISTER_SPECS,
        payload: {
            data,
        },
    };
}

export function receiveRegisterSpecificationError(data) {
    return {
        type: RECEIVE_REGISTER_SPECS_KO,
        payload: {
            data,
        },
    };
}

export function fetchRegisterSpecificationRequest() {
    return {
        type: FETCH_REGISTER_SPECS_REQUEST,
    };
}

export function fetchRegisterSpecification(token) {
    return (dispatch) => {
        dispatch(fetchRegisterSpecificationRequest());
        data_fetch_api_resource(token, "contracts/" )
            .then(parseJSON)
            .then(response => {
                dispatch(receiveRegisterSpecification(response.result));
            });
    };
}


