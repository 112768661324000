import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { i18n } from "../../config";
import AttachmentsList from "../Attachments/AttachmentsList";


const getTestData = ({maxAttachmentByCateg=10}) => {
  const attachments = [];
  for (let categoryName of ['category one', 'category two', 'category three']) {
    
    const attachmentsQty = Math.floor(Math.random() * (maxAttachmentByCateg-1)) + 1; // Returns a random integer from 1 to maxAttachmentByCateg
    for (let i of Array(attachmentsQty)) {
      const randomNum = Math.floor(Math.random() * 1000000000);

      attachments.push({
        "category_code": categoryName,
        'category_name': categoryName,
        'id': randomNum,
        'name': `attch_name_${randomNum}`,
        'filename': `attch_datas_fname_${randomNum}.txt`,
        'created_date': new Date(Math.floor(Math.random() * Date.now())).toLocaleDateString(), //attch.create_date
        'size_text': `${Math.floor(Math.random() * 100)}${['KB', 'MB', 'GB'][Math.floor(Math.random() * 3)]}`
      });
    };
  }
  return attachments;
}

// Expected data: attachmentsList: {category_code, category_name, created_date, filename, id, name}[]
const AttachmentsCard = ({attachmentsList}) => {
  attachmentsList = attachmentsList ?? [];
  // attachmentsList = getTestData({maxAttachmentByCateg: 4}); // Activate me to use test data

  const attachments = {};
  for (let attch of attachmentsList) {
    if (!attachments.hasOwnProperty(attch.category_name))
      attachments[attch.category_name] = [];
    attachments[attch.category_name].push(attch);
  }
  
  const attachmentsQty = Object.values(attachments).reduce((acumulator, currentValue) => acumulator + currentValue.length, 0);


  return (
    <Card>
      <CardContent>
        <Box>
          <Typography variant="h5">
            { i18n.t('common:text.attachments_title') } ({attachmentsQty})
          </Typography>
        </Box>
        <Box>
          { 
          Object.keys(attachments).map((category) => (
            <Box marginBottom={2}>
              <AttachmentsList title={category} 
                attachments={attachments[category]} 
                downloadURL="contract/download_attachment/:attachmentID"/>
            </Box>
          )) 
          }
        </Box>
      </CardContent>
    </Card>
  )
}


export default AttachmentsCard;