import _ from "lodash";
import Cookies from "js-cookie";
import ReactGA from "react-ga";
import Settings from "../settings";
import { marketingHook } from "../overrides/hooks/init";

const saveLang = (lang) => {
  if (
    !Cookies.get("rcl_consent_given") ||
    (Cookies.get("rcl_consent_given") && Cookies.get("rcl_preferences_consent"))
  ) {
    Cookies.set("lang", lang);

    console.log("Lang saved");
  } else {
    Cookies.remove("lang");
  }
};

const getLang = () => {
  return Cookies.get("lang");
};

const getMaintenanceBypass = () => {
  return Cookies.get("maintenance_bypass");
};

const hasAcceptedCookies = () => {
  if (Cookies.get("rcl_consent_given")) {
    return true;
  }
  return false;
};

const statisticsConsentGiven = () => {
  return Cookies.get("rcl_statistics_consent");
};

const marketingConsentGiven = () => {
  return Cookies.get("rcl_marketing_consent");
};

const preferenceConsentGiven = () => {
  return Cookies.get("rcl_preferences_consent");
};

const acceptConsentCookies = () => {
  Cookies.set("rcl_consent_given", true);
};

const acceptMarketingCookies = () => {
  Cookies.set("rcl_marketing_consent", true);
};

const acceptPreferencesCookies = () => {
  Cookies.set("rcl_preferences_consent", true);
};

const acceptStatisticCookies = () => {
  ReactGA.initialize(_.get(Settings, "analytics.key"));
  Cookies.set("rcl_statistics_consent", true);
};

const acceptAllCookies = () => {
  ReactGA.initialize(_.get(Settings, "analytics.key"));
  Cookies.set("rcl_marketing_consent", true);
  Cookies.set("rcl_consent_given", true);
  Cookies.set("rcl_preferences_consent", true);
  Cookies.set("rcl_statistics_consent", true);
};

const deleteStatisticCookies = () => {
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
  window[`ga-disable-${_.get(Settings, "analytics.key")}`] = true;
};

const updateStatisticScripts = (location) => {
  if (
    _.get(Settings, "features.analytics", false) &&
    _.get(Settings, "analytics.key", false) &&
    statisticsConsentGiven()
  ) {
    ReactGA.pageview(location.pathname + location.search);
  }
};

const updateMarketingScripts = () => {
  if (Cookies.get("rcl_marketing_consent")) {
    marketingHook();
  }
};

const cookies = {
  saveLang,
  getLang,
  getMaintenanceBypass,
  acceptConsentCookies,
  statisticsConsentGiven,
  marketingConsentGiven,
  preferenceConsentGiven,
  deleteStatisticCookies,
  acceptStatisticCookies,
  updateStatisticScripts,
  acceptMarketingCookies,
  updateMarketingScripts,
  acceptPreferencesCookies,
  acceptAllCookies,
  hasAcceptedCookies,
};

export default cookies;