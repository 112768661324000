import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newGasContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import Settings from "../../settings";
import CheckboxField from "../form-fields/CheckboxField";
import TextField from "../form-fields/TextField";
import Address from "../Address";
import { i18n } from "../../config";

import { CircularProgress, Button, Box, Typography, Divider } from "@material-ui/core"

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const Confirm = (props) => {

  const handleSubmit = async (values) => {
    props.submitConfirmData(values);

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  return (
    <div>
      { _.get(Settings, "newGasContract.sectionHeaders", false) &&
        <Box>
          <Typography variant="h4">{i18n.t('common:text.contractation_confirmation')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          promotionalCode: props.promotionalCode,
          acceptOVConditions: props.acceptOVConditions,
          acceptGdpr: props.acceptGdpr,
        }}
        validate={(values) => {
          const errors = {};

          if (!props.isAuthenticated) {
            if (!values.acceptOVConditions) {
              _.set(errors, "acceptOVConditions", i18n.t('common:text.required_field'));
            }
            if (!values.acceptGdpr) {
              _.set(errors, "acceptGdpr", i18n.t('common:text.required_field'));
            }
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div style={{ marginTop: 25 }}>
              <Box>
                <Typography variant="h5">
                  {i18n.t('common:text.contractation_about_home')}
                </Typography>
              </Box>
              <div style={{ marginLeft: 50 }}>
                <div>
                  {i18n.t('common:text.contractation_cups_validation')} {props.cups} (
                  {i18n.t('common:text.contractation_home_validation')} {!props.residenceType && "no"}{" "}
                  {i18n.t('common:text.contractation_usual_validation')})
                </div>
                {!_.isEmpty(props.address) && (
                  <div>
                    {i18n.t('common:text.contractation_address_validation')}
                    <Address readonly value={props.address} />
                  </div>
                )}
                <div>
                  {i18n.t('common:text.contractation_cnae_validation')}{" "}
                  {_.get(props, "cnae.descripcio", "?")}
                </div>
              </div>

              <Box>
                <Typography variant="h5">
                  {i18n.t('common:text.contractation_about')}{" "}
                  {props.company
                    ? i18n.t('common:text.contractation_enterprise')
                    : i18n.t('common:text.contractation_you')}
                </Typography>
              </Box>
              <div style={{ marginLeft: 50 }}>
                {!props.company && (
                  <div>
                    {i18n.t('common:text.contractation_name_validation')} {props.surName1}{" "}
                    {props.surName2}, {props.name}
                  </div>
                )}
                {props.company && (
                  <div>
                    {i18n.t('common:text.contractation_company_validation')} {props.name}
                  </div>
                )}
                <div>
                  {i18n.t('common:text.contractation_dni_validation')}{" "}
                  {_.get(props, "vat", "").replace("ES", "")}
                </div>
                {!_.isEmpty(props.invoiceAddress) && (
                  <div>
                    {i18n.t('common:text.contractation_address_validation')}
                    <Address readonly value={props.invoiceAddress} />
                  </div>
                )}
                <div>
                  {i18n.t('common:text.contractation_mobile_validation')} {props.mobile}
                </div>
                <div>
                  {i18n.t('common:text.contractation_email_validation')} {props.email}
                </div>
                {props.company && (
                  <div>
                    <h4 style={{ marginLeft: -20 }}>
                      {i18n.t('common:text.contractation_legal_representative')}
                    </h4>
                    <Box>
                      <Typography variant="h5">
                        {i18n.t('common:text.contractation_legal_representative')}
                      </Typography>
                    </Box>
                    <div>
                      {props.surName1Representante}{" "}
                      {props.surName2Representante}, {props.nameRepresentante}
                    </div>
                    <div>{props.vatRepresentante.replace("ES", "")}</div>
                  </div>
                )}
              </div>

              {props.selectedProduct && (
                <div>
                  <Box>
                    <Typography variant="h5">
                        {i18n.t('common:text.contractation_about_gas_contract')}
                    </Typography>
                  </Box>
                  <div style={{ marginLeft: 50 }}>
                    <div>
                      {i18n.t('common:text.contractation_tariff')}: {props.selectedProduct.name}
                    </div>
                  </div>
                </div>
              )}

              <Box>
                <Typography variant="h5">
                    {i18n.t('common:text.contractation_about_payment')}
                </Typography>
              </Box>
              <div style={{ marginLeft: 50 }}>
                <div>
                  {i18n.t('common:text.contractation_iban_direct_debit')} {props.iban}
                </div>
              </div>

              {_.get(Settings, "features.promotionalCode", false) && (
                <div>
                  <Box>
                    <Typography variant="h5">
                      {i18n.t('common:text.contractation_promotional_code_title')}
                    </Typography>
                  </Box>
                  <div style={{ marginLeft: 50 }}>
                    <Field
                      name="promotionalCode"
                      component={TextField}
                      label={i18n.t('common:text.contractation_promotional_code')}
                    />
                  </div>
                </div>
              )}

              {!props.isAuthenticated && (
                <div>
                  <Box>
                    <Typography variant="h5">
                      {i18n.t('common:text.contractation_conditions')}
                    </Typography>
                  </Box>
                  <div style={{ marginLeft: 50 }}>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Field
                        name="acceptOVConditions"
                        label={i18n.t('common:text.contractation_ov_aceptation')}
                        component={CheckboxField}
                      />
                      <a
                        style={{ marginTop: 17 }}
                        target="_blank"
                        href={_.get(Settings, "links.conditions", "")}
                        rel="noreferrer"
                      >
                        {i18n.t('common:text.contractation_ov_conditions')}
                      </a>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Field
                        name="acceptGdpr"
                        label={i18n.t('common:text.contractation_privacy_policy_aceptation')}
                        component={CheckboxField}
                      />
                      <a
                        style={{ marginTop: 17 }}
                        target="_blank"
                        href={_.get(Settings, "links.gdpr", "")}
                        rel="noreferrer"
                      >
                        {i18n.t('common:text.contractation_privacy_policy')}
                      </a>
                    </div>
                  </div>
                </div>
              )}
              <Divider style={{marginTop: 20, marginBottom: 20}}/>
              <Button
                variant={'text'}
                onClick={handleGoBack}
                style={{ marginRight: 12 }}
                disabled={submitting}
              >
                {i18n.t('common:text.contractation_previous')}
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                type="submit"
                disabled={submitting}
              >
                {submitting ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.contractation_send')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
