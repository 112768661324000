import _ from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions/invoicesGas';
import { debug } from '../utils/debug';
import { regional_settings } from '../constants';
import { locale_code } from '../constants/i18n';
import { i18n, dayjs } from "../config";

import { ContentHeader } from './ContentHeader';

import { Notification } from './Notification';
import { LoadingAnimation } from './LoadingAnimation';
import { SmartTable } from './SmartTable';

import { Chart } from './Chart';
import { ArrowForward, ArrowBack, ErrorOutline, GetApp } from '@material-ui/icons';
import { CircularProgress, Button, Switch, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import Settings from "../settings";

function mapStateToProps(state) {
    const invoices = state.invoicesGas;
    return {
        data: invoices,
        offset: invoices.offset_items,
        page_items: invoices.page_items,
        token: state.auth.token,
        loaded: invoices.loaded,
        isFetching: invoices.isFetching,
        message_text: invoices.message_text,
        filterContract: state.contractsGas.filterContract,
        toggleLeft: false,
        download_status: invoices.download_status,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

const style = {
    buttonAdd: {
        marginRight: 20,
    },
    buttonPosition: {
        textAlign: 'right',
    },
    table: {
        marginTop: 20,
    },
    chart: {
        marginBottom: 50,
    },
    aggregationsCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    toggle: {
      marginTop: 7,
      marginLeft: 12,
    },
    labelToggle: {
        marginTop: 7,
        marginLeft: 7,
    },
};


class InvoicesView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message_text: null,
            contractId: props.params ? props.params.contractId : null,
            toggleLeft: false,
            offset: 0,
            page_items: _.get(Settings, "invoices.itemsPerPage", 100),
        };
        dayjs.locale(locale_code);
    }


    componentDidMount() {
        if (_.isEmpty(this.props.data.items) && !this.props.loaded) {
            this.fetchData(); 
        }
    }

    fetchData(initial = true, offset = 0) {
        //const token = this.props.token;
        this.props.fetchInvoices(null, this.state.contractId, initial, offset);
    }

    toggleRender = (event, status) => {
        this.setState({
            toggleLeft: status,
            message_open: false,
        });
        this.animateChart = false;
    };

    refreshData() {
        this.fetchData(false);
        this.setState({
            message_open: true,
        });
    }

    exportInvoice = (event, invoiceID) => {
        event.preventDefault();
        this.props.exportInvoice(this.props.token, invoiceID);
    };

    previous_page = () => {
        const offset = this.props.offset - this.props.page_items;
        this.setState({offset});
        this.fetchData(false, offset);
    };

    next_page = () => {
        const offset = this.state.offset + this.state.page_items;
        this.setState({ offset });
        this.fetchData(false, offset);
    };

    chartInvoices(invoices, energy) {
        let components = {};

        const number_of_months = 12;

        // Initialize last 3 years
        const max_years = 3;
        const current_year = dayjs().year();
        const init_year = current_year - max_years;

        let energy_per_year = {};
        let amount_per_year = {};
        for (let year=init_year; year <= current_year; year++) {
            energy_per_year[year] = [];
            amount_per_year[year] = [];

            // Assign the 12 months
            for (let i=0; i<number_of_months; i++) {
                energy_per_year[year].push({'name': i, 'total':0});
                amount_per_year[year].push({'name': i, 'total':0});
            }
        }

        invoices.forEach((invoice) => {
            const end_date = dayjs(invoice.end_date);

            // Extract the date
            const month = end_date.month();
            const year = end_date.year();
            const year_lite = year - 2000;

            const sign = invoice.rectifirectificative_type === 'A' ? -1 : 1

            const amount = (parseFloat(invoice.amount_total) * sign);
            const energy = (parseFloat(invoice.energy_consumed) * sign);
            const contract = invoice.contract.name;

            // Ensure initialize correctly components with all CUPS
            if (!(contract in components)) {
                components[contract] = {
                    'title': contract,
                }
            }

            // Set the energy and amount
            energy_per_year[year][month][contract] = energy;
            amount_per_year[year][month][contract] = amount;

            // Override title by default by shorted mont and the year
            amount_per_year[year][month]['name'] = i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
            energy_per_year[year][month]['name'] = i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
        });

        let final_amount = [];
        let final_energy = [];
        for (let year=init_year; year <= current_year; year++) {
            for (let month=0; month < number_of_months; month++) {
                //Select just non-empty elements
                amount_per_year[year][month].total = 0;
                if (Object.keys(amount_per_year[year][month]).length > 2) {
                    // Calculate totals for each month
                    _.forEach(Object.keys(amount_per_year[year][month]), (k) => {
                        if (k !== 'total' && k !== 'name') {
                            amount_per_year[year][month].total += amount_per_year[year][month][k];
                            energy_per_year[year][month].total += energy_per_year[year][month][k];
                        }
                    });

                    // Format decimals
                    amount_per_year[year][month].total = Number(amount_per_year[year][month].total).toFixed(2);
                    energy_per_year[year][month].total = Number(energy_per_year[year][month].total).toFixed(0);

                    const the_amount = Object.assign({}, amount_per_year[year][month]);
                    const the_energy = Object.assign({}, energy_per_year[year][month]);

                    final_amount.push(the_amount);
                    final_energy.push(the_energy);
                }
            }
        }

        const data = energy ? final_energy : final_amount;

        return {
            data,
            components,
        }
    }

    handleOpenDialog = (e) => {
        e.preventDefault();
        this.setState({ dialogOpen: true });
    };
    
    handleCloseDialog = () => {
        this.setState({ dialogOpen: false });
    };

    JSON_to_arrays = (invoices) => {
        const header = [
            {
                title: null
            },
            {
                title: i18n.t('common:text.invoices_invoice_number'),
            }, {
                title: i18n.t('common:text.invoices_date'),
            }, {
                title: i18n.t('common:text.invoices_period'),
            }, {
                title: i18n.t('common:text.invoices_address'),
            }, {
                title: i18n.t('common:text.invoices_import'),
            }, {
                title: i18n.t('common:text.invoices_energy'),
            }, {
                title: i18n.t('common:text.invoices_payed'),
            }, {
                title: i18n.t('common:text.invoices_actions'),
            }
        ];

        const props = this.props;
        const that = this;
        const content = invoices.map((invoice, index) => {
            // Invoice date
            const invoice_date = dayjs(invoice.date).format("L");

            //Start and End date (period)
            const start_date = dayjs(invoice.date).format("L");
      
            const end_date = dayjs(invoice.end_date).format("L");
      
            const period = start_date + " > " + end_date;

            const disabled_button = (
                invoice.id in props.download_status
                && props.download_status[invoice.id]
            );

            const download_button = (
                <Button
                    title={"Download invoice"}
                    color={'primary'}
                    variant={'contained'}
                    disabled={disabled_button}
                    onClick={(e) => props.exportInvoice(props.token, invoice.id)}
                    style={{marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden'}}
                >
                    {disabled_button?<CircularProgress size={25}/>:<GetApp/>}
                    {i18n.t('common:text.invoices_view_download_button')}
                </Button>
            );


            let paid = (invoice.paid)? i18n.t('common:text.invoice_paid') : i18n.t('common:text.invoice_not_paid');
            if (invoice.paid && invoice.rectificative_type === 'A') {
                paid = i18n.t('common:text.invoice_refunded');
            }
            if (invoice.amount_debt) {
                paid = <strong>Impagada</strong>;
                
                // We enable a link to show the pending state history only if there
                // is more than one item to show.
                if (invoice.pending_state_history && invoice.pending_state_history.length > 1) {
                    const dialogActions = [
                        <Button
                            color={'primary'}
                            variant={'text'}
                            onClick={that.handleCloseDialog}
                        >
                            {i18n.t('common:text.invoices_dialog_close')}
                        </Button>
                    ];
                    paid = (
                        <div>
                            <Button
                                onClick={(e) => that.handleOpenDialog}
                                color='primary'
                                variant={'text'}
                                style={{padding: 0}}
                            >
                                {paid}
                            </Button>
                            <Dialog
                                open={that.state.dialogOpen || false}
                                onClose={that.handleCloseDialog}
                            >
                                <DialogTitle>
                                    {i18n.t('common:text.invoices_dialog_title')}
                                </DialogTitle>
                                <DialogContent>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{i18n.t('common:text.invoices_dialog_table_date')}</th>
                                                <th>{i18n.t('common:text.invoices_dialog_table_description')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoice.pending_state_history.map((i) => (
                                                <tr>
                                                    <td>{dayjs(i.change_date).format("L")}</td>
                                                    <td>{i.pending_state.ov_description}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </DialogContent>
                                <DialogActions>
                                    {dialogActions}
                                </DialogActions>
                            </Dialog>
                        </div>
                    );
                }
            }


            const values = [
                invoice.amount_debt ? <ErrorOutline color="#f00"/> : null,
                invoice.number,
                invoice_date,
                period,
                invoice.cups.street,
                `${invoice.rectificative_type === 'A' ? '-' : ''}${invoice.amount_total_printable}`,
                `${invoice.rectificative_type === 'A' ? '-' : ''}${invoice.energy_consumed.toLocaleString(locale_code)} ${regional_settings.energy_unit}`,
                paid,
                download_button,
            ]

            return values;
        });

        return {
            header,
            content
        };
    }

    render() {
        const {data, loaded} = this.props;
        const invoices = data.items;
        const {contractId, toggleLeft} = this.state;

        const adaptedData =
            (loaded) &&
                this.JSON_to_arrays(invoices);

        const chartData =
            (loaded) &&
                this.chartInvoices(invoices, toggleLeft);

        const left=i18n.t('common:text.amount');
        const right=i18n.t('common:text.energy');

        const onToggle = this.toggleRender;
        const the_toggle =  (
            <div>
              {
              (toggleLeft)?
              <div
                  id="togglePicture"
                  style={style.aggregationsCenter}
              >
                  <div style={style.labelToggle}>
                    {left}
                  </div>
                  <div id="toogleElement">
                      <Switch
                          onChange={onToggle}
                          checked={toggleLeft}
                      />
                  </div>
                  <div style={style.toggle}>
                      <b>{right}</b>
                  </div>
              </div>

              :

              <div
                  id="togglePicture"
                  style={style.aggregationsCenter}
              >
                  <div style={style.labelToggle}>
                      <b>{left}</b>
                  </div>
                  <div id="toogleElement">
                      <Switch
                          onChange={onToggle}
                          checked={toggleLeft}
                      />
                  </div>
                  <div style={style.toggle}>
                    {right}
                  </div>
              </div>
              }
            </div>
        );


        const unit = (!toggleLeft) ? regional_settings.currency : regional_settings.energy_unit;

        const chart = <Chart
            data={chartData.data}
            components={chartData.components}
            unit={unit}
            animated={true}
            stacked={true}
        />

        const subTitle = (contractId && this.props.filterContract)?`${i18n.t('common:text.invoices_view_subtitle')}: ${this.props.filterContract.cups.full_address}`:null;
        const start_items = data.offset_items;
        const end_items = Math.min(start_items + data.page_items, data.total_items);

        return (
            <div>
                <Notification
                    message={this.props.message_text}
                    time={6000}
                    open={true}
                />

        		<ContentHeader
        		    title={i18n.t('common:text.invoices_view_title_gas')}
        		    addButton={false}

        		    refreshButton={true}
        		    refreshClickMethod={() => this.refreshData()}
                />
            {
                this.props.loaded?
                <div style={style.table}>

                {//if subtitle
                    {subTitle} &&

                        <div style={style.subtitle}>
                            <h3>{subTitle}</h3>
                        </div>

                }


                {
                (invoices.length > 0)?
                    (
                      <div>
                        <h3>
                            {i18n.t('common:text.invoices_contracts_chart')}
                        </h3>
                        <div>
                            {the_toggle}
                        </div>

                        <div style={style.chart}>
                            {chart}
                        </div>

                        <SmartTable
                            header={adaptedData.header}
                            data={adaptedData.content}
                        />
                      </div>
                    )
                 :
                    (
                         <h4>{i18n.t('common:text.invoices_view_empty_list')}</h4>
                    )
                  }
                </div>
            :
                <div>
                    <LoadingAnimation />
                </div>
            }
            {(data.page_items < data.total_items) &&
                    <div style={{ textAlign: "center" }}>
                        {(start_items > 0) &&
                            <Button
                                variant={'text'}
                                onClick={() => this.previous_page()}
                                disabled={data.isFetching}
                            >
                                {<ArrowBack />}
                                {"Anterior"}
                            </Button>
                        }
                        <span>Mostrando de {start_items} a {end_items} de {data.total_items}</span>
                        {(end_items < data.total_items) &&
                            <Button
                                variant={'text'}
                                onClick={() => this.next_page()}
                                disabled={data.isFetching}
                            >
                                {"Siguiente"}
                                <ArrowForward />
                            </Button>
                        }
                    </div>
                }
                {debug(invoices)}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesView);

InvoicesView.propTypes = {
    fetchInvoices: PropTypes.func,
    loaded: PropTypes.bool,
    data: PropTypes.any,
    token: PropTypes.string,
};
