import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { data_fetch_api_resource } from "../../utils/http_functions";
import { i18n } from "../../config";

import { MenuItem, Select, FormControl, InputLabel, FormHelperText } from "@material-ui/core"

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}

const AccessTariffSelectField = ({ input: { onChange, value }, disabled, initialTariff, label, readOnly, onAvailableItemsUpdate, onSelectUpdate, gas, power, meta, ...rest }) => {

  const [availableItems, setAvailableItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(_.get(initialTariff, 'id', ''));
  const prevPower = usePrevious(power.power);
  const prevPower2 = usePrevious(power.power2);

  const fetchAvailableItems = async () => {
    // power is a dict '{power: 12, power2: 13, ...}. We need to transform the dict into an array Object.values(),
    // filter those powers which are undefined or null, parse them as ints and finally
    // explode the final array to pass the powers as singular arguments into the Math.max function.'
    const elect_params = (power === null || _.isEmpty(power)) ? `tariff` : `tariff?power=${Math.max(...Object.values(power).filter(pow => typeof pow === 'string').map(pow => parseInt(pow)))}`;
    const endpoint = gas ? "gas/" + elect_params : elect_params;
    const version = gas ? 2 : 1;

    // TODO: We should consider doing this with actions and reducers
    await data_fetch_api_resource(null, endpoint, version).then(
      (response) => {
        if (response.status === 200) {
          const items = response.data;

          setAvailableItems(items);
        }
      }
    );
  };

  const handleChange = (event, index, value) => {
    setSelectedItem(event.target.value);
    onChange(event.target.value);
    if (typeof onSelectUpdate === "function") {
      onSelectUpdate(event.target.value, index.props.children);
    }
  };

  useEffect(() => {
    if(!selectedItem && Number.isInteger(initialTariff)) {
        setSelectedItem(initialTariff);  
    }

    if( power != {} ) {
      if(
          (!power.power && !power.power2) 
          || (parseInt(prevPower) < 16 && parseInt(power.power) >= 16 && parseInt(power.power2) < 16) 
          || (parseInt(prevPower2) < 16 && parseInt(power.power2) >= 16 && parseInt(power.power) < 16)
          || (parseInt(prevPower) >= 16 && parseInt(power.power) < 16 && parseInt(power.power2) < 16) 
          || (parseInt(prevPower2) >= 16 && parseInt(power.power2) < 16 && parseInt(power.power) < 16)
      ) {
        handleChange({target: {value: ''}}, {props: {children: ''}}, '');
      };

      fetchAvailableItems();
    }
  }, [power.power, power.power2, power.power3, power.power4, power.power5, power.power6]);

  return (
    <div>
      <FormControl
        disabled={readOnly}
        style={{width: "100%"}}
      >
        <InputLabel shrink htmlFor="">
          {(label || i18n.t('common:text.contractation_access_tariff')) + "*"}
        </InputLabel>
        <Select
          value={selectedItem}
          onChange={handleChange}
          autoWidth={true}
          {...rest}
        >
          {(!_.isEmpty(availableItems) &&
            availableItems.map((c) => {
              return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
            })) ||
            []}
        </Select>
      </FormControl>
      {!!(meta.touched) &&
        <FormHelperText error={true}>
          {meta.error}
        </FormHelperText>
      }
    </div>
  );
};

export default AccessTariffSelectField;
