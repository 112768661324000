import _ from "lodash";

export const collectFormData = (data) => {
  const formData = {
    owner: {
      type: data.company ? "J" : "F",
      changeOwner: data.changeOwner !== 'changeOwner',
    },
    cups: {
      name: data.cups,
      state: _.get(data, "address.state.id", null),
      municipi: _.get(data, "address.city.ine", null),
      poblacio: _.get(data, "address.city.city", null),
      tv: _.get(data, "address.tv.id", null),
      nv: _.get(data, "address.nv", null),
      pnp: _.get(data, "address.pnp", null),
      bq: _.get(data, "address.bq", null),
      es: _.get(data, "address.es", null),
      pt: _.get(data, "address.pt", null),
      pu: _.get(data, "address.pu", null),
      dp: _.get(data, "address.zipcode", null),
      aclarador: _.get(data, "address.aclarador", null),
    },
    cnae: {
      cnae: _.get(data, "cnae.id", null),
      vivienda: data.residenceType,
    },
    owner_address: {
      different_address: !data.sameInvoiceAddress,
    },
    bank: {
      iban: data.iban,
      owner_is_bank_owner: !!data.isOwner,
    },
    activeContract: data.activeContract || null,
    authType: data.authType || null,
    accept_gdpr: data.acceptGdpr || false,
    accept_ov_conditions: data.acceptOVConditions || false,
    comerOrigin: data.comerOrigin,
    invoice: data.invoice || [],
    nif_faceup: _.get(data, 'nifFaceup[0]') || null,
    nif_facedown: _.get(data, 'nifFaceup[1]') || null,
    promotional_code: data.promotionalCode,
  };

  if (data.address && data.address.ref_catastral) {
    formData.cups.ref_catastral = data.address.ref_catastral;
  }

  if (data.sameInvoiceAddress) {
    formData.owner_address = Object.assign(formData.owner_address, {
      state: _.get(data, "address.state.id", null),
      municipi: _.get(data, "address.city.ine", null),
      poblacio: _.get(data, "address.city.city", null),
      tv: _.get(data, "address.tv.id", null),
      nv: _.get(data, "address.nv", null),
      pnp: _.get(data, "address.pnp", null),
      bq: _.get(data, "address.bq", null),
      es: _.get(data, "address.es", null),
      pt: _.get(data, "address.pt", null),
      pu: _.get(data, "address.pu", null),
      zip: _.get(data, "address.zipcode", null),
      aclarador: _.get(data, "address.aclarador", null),
      email: _.get(data, "email", null),
      mobile: _.get(data, "mobile", null),
    });
    if (data.address && data.address.ref_catastral) {
      formData.owner_address.ref_catastral = data.address.ref_catastral;
    }
  } else {
    formData.owner_address = Object.assign(formData.owner_address, {
      state: _.get(data, "invoiceAddress.state.id", null),
      municipi: _.get(data, "invoiceAddress.city.ine", null),
      poblacio: _.get(data, "invoiceAddress.city.city", null),
      tv: _.get(data, "invoiceAddress.tv.id", null),
      nv: _.get(data, "invoiceAddress.nv", null),
      pnp: _.get(data, "invoiceAddress.pnp", null),
      bq: _.get(data, "invoiceAddress.bq", null),
      es: _.get(data, "invoiceAddress.es", null),
      pt: _.get(data, "invoiceAddress.pt", null),
      pu: _.get(data, "invoiceAddress.pu", null),
      zip: _.get(data, "invoiceAddress.zipcode", null),
      aclarador: _.get(data, "invoiceAddress.aclarador", null),
      email: _.get(data, "email", null),
      mobile: _.get(data, "mobile", null),
    });
    if (data.invoiceAddress && data.invoiceAddress.ref_catastral) {
      formData.owner_address.ref_catastral = data.invoiceAddress.ref_catastral;
    }
  }

  if (data.address && data.address.ref_catastral) {
    formData.cups.ref_catastral = data.address.ref_catastral;
  }
  if (data.company) {
    formData.owner = Object.assign(formData.owner, {
      name: data.name,
      juridic_vat: "ES" + data.vat,
      rep_name: data.nameRepresentante,
      rep_surname1: data.surName1Representante,
      rep_surname2: data.surName2Representante,
      rep_vat: data.vatRepresentante,
      rep_name_full: `${data.surName1Representante || ""} ${data.surName2Representante || ""} , ${data.nameRepresentante || ""}`
    });
    formData.cif = data.cif;
  } else {
    formData.owner = Object.assign(formData.owner, {
      fisic_vat: data.vat,
      first_name: data.name,
      surname1: data.surName1,
      surname2: data.surName2,
    });
  }

  formData.commsLanguage = data.commsLanguage;

  formData.power = data.power;
  formData.power2 = data.power2 || null;
  formData.power3 = data.power3 || null;
  formData.power4 = data.power4 || null;
  formData.power5 = data.power5 || null;
  formData.power6 = data.power6 || null;
  if (data.accessTariff) {
    formData.tarifaATR = data.accessTariff || null;
  }
  formData.selfcons = data.selfcons;
  formData.selfcons_attach = data.selfconsAttachment || [];
  formData.selfconsCode = data.selfconsCode;

  if (data.selectedProduct) {
    formData.tarifa = data.selectedProduct.id || null;
  } else {
    if (data.availableProducts && !_.isEmpty(data.availableProducts[0])) {
      formData.tarifa = data.availableProducts[0].id;
    }
  }

  return formData;
};
