import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from "../../config";

const LogoImg = () => {
  return <img
    src="/images/logoBar.png"
    style={{
      maxHeight: "90%",
    }}
    alt="logo"
  />
}

export const LogoBar = (props) => {

  if (i18n.exists("common:links.logo")) {
    return (
      <a href={i18n.t("common:links.logo")} style={{height: "100%", width: "0", display: "flex", alignItems: "center"}}>
        <LogoImg />
      </a>
    );
  } else {
    return (
      <div style={{height: "100%", width: "0", display: "flex", alignItems: "center"}}>
        <LogoImg />
      </div>
    )
  }

};

LogoBar.propTypes = {
    openLinkInNewTab: PropTypes.bool,
};
