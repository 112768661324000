import { useState } from 'react';

import { 
  Container, Button, Link, Typography, Box, Paper,
  FormGroup, FormControlLabel, Checkbox, Grid
} from "@material-ui/core";

import Settings from "../../settings";
import { i18n } from "../../config";

const CookiePersonalizeDialog = (props) => {
  const [acceptedCookies, setAcceptedCookies] = useState({
    consent: true,
    preferences: false,
    statistics: false,
    marketing: false,
  });

  const handleChange = (event) => {
    setAcceptedCookies({ ...acceptedCookies, [event.target.name]: event.target.checked });
  };

  return (
    <Box sx={{position: "fixed", bottom: 0}}>
      <Paper elevation={24} style={{padding: 30}}>
        <Typography variant="h4">{i18n.t('common:text.cookie_consent_title')}</Typography>
        <Typography>{i18n.t('common:text.cookie_consent_text')}</Typography>
        <FormGroup row style={{justifyContent: "center"}}>
          <FormControlLabel
            control={<Checkbox checked={acceptedCookies.consent} onChange={handleChange} name="consent" />}
            label={i18n.t('common:text.cookie_necessary_text')}
          />
          <FormControlLabel
            control={<Checkbox checked={acceptedCookies.preferences} onChange={handleChange} name="preferences" />}
            label={i18n.t('common:text.cookie_preferences_text')}
          />
          <FormControlLabel
            control={<Checkbox checked={acceptedCookies.statistics} onChange={handleChange} name="statistics" />}
            label={i18n.t('common:text.cookie_statistics_text')}
          />
          <FormControlLabel
            control={<Checkbox checked={acceptedCookies.marketing} onChange={handleChange} name="marketing" />}
            label={i18n.t('common:text.cookie_marketing_text')}
          />
        </FormGroup>
        <Container>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
              <Link variant="button" href={Settings.links.cookies} target="_blank" rel="noreferrer">{i18n.t('common:text.cookie_policy_text')}</Link>
            </Grid>
            <Grid item>
              <Button
                variant={'contained'}
                className="container-element"
                onClick={() => props.acceptCookies(acceptedCookies)}
              >
                {i18n.t('common:text.cookie_consent_button')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Box>
  );
};

export default CookiePersonalizeDialog;