import _ from "lodash";
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Area, AreaChart, Bar, Brush, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { theme } from '../../config';
import Settings from "../../settings";
import { generateColorList } from "../../utils/colors";
const styles = {
    dialog: {
        width: '80%',
        maxWidth: 'none',
    },
    graphContainer: {
        maxWidth: '500px',
        maxHeight: '200px',
    },
    tooltip: {
      backgroundColor: "white",
      border: "1px solid grey",
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
    },
    tooltipTitle: {
       margin: "15px 0 15px 0",
    },
    legend: {
      width: "100%",
      paddingTop: "1vh",
    },
};

class CustomTooltip extends Component {

    render() {
        const {active} = this.props;

        const group_title = (this.props.group_title) ? this.props.group_title + " " : "";

        if (active) {
            const {payload, label} = this.props;

            return (
                <div style={styles.tooltip} className="custom-tooltip">
                    <h4 style={styles.tooltipTitle}><strong>{group_title}{label}</strong>
                    </h4>
                    {
                        Object.keys(payload).map(function (comp, i) {
                            const component = payload[i];

                            let {name} = component;

                            let componentStyle = {
                                marginTop: "10px"
                            }

                            let containerStyle = {
                                display: "flex"
                            }

                            let squareStyle = {
                                width: "20px",
                                height: "20px",
                                backgroundColor: component.color,
                                borderRadius: "4px"
                            }

                            let textStyle = {
                                marginLeft: "5px",
                                color: "black"
                            }

                            if (component.name === "total") {
                                name = component.name.toUpperCase();
                                return (
                                    <p key={"tooltip" + i} style={componentStyle}>
                                        <strong>{name}</strong>: {component.value} {component.unit}
                                    </p>
                                )
                            }

                            return (
                                <div key={"tooltip" + i} style={containerStyle}>
                                    <div style={squareStyle}></div>
                                    <div style={textStyle}><strong>{name}</strong>: {component.value} {component.unit}</div>
                                </div>
                            )
                        })
                    }
                </div>
            );
        }

        return null;
    }
}


export class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled_fields : null,
            is_loading : true,
            hover : null,
            colors: null,
        };
    }


    // toDo review why the hour 0 stills appears

    selectBar = (e) => {
        let dict_fields = this.state.enabled_fields
        dict_fields[e.dataKey] = !dict_fields[e.dataKey]
        this.setState({enabled_fields : dict_fields})
    };

    handleLegendMouseEnter = (e) => {
        this.setState({hover : e.dataKey})
    };

    handleLegendMouseLeave = (e) => {
        this.setState({hover : null})
    };

    render() {
        const data = this.props.data;
        const components = this.props.components;
        const startingColor = "#61A2A5"; // TODO: Agafa el color primary, posa una prop que es digui bar mainColor per el component
        const that = this;
        let colors = this.state.colors;
      
        
        const getStackedId = (component) => {
            if(component.search("reactiva") >= 0){
                return "reactiva";
            } else if (component.search("exportada") >= 0) {
                return "exportada";
            } else {
                return "activa"
            }
        }

        const howManyComponents = Object.keys(components).length;

        const stacked = (this.props.stacked)?"1":null;
        const lineGraph = !!(this.props.lineGraph);

        const height = (this.props.height)?this.props.height:500;
        //const width = (this.props.width)?this.props.width:1024;

        const isAreaChart = (this.props.areaChart)?this.props.areaChart:false;
            
        const isAnimated = (this.props.animated)?this.props.animated:false;

        const ignoreMaxContracts = (this.props.ignoreMaxContracts)?this.props.ignoreMaxContracts:false;
        const group_title = (this.props.group_title)?this.props.group_title:"";
        const compoundBars = (this.props.compoundBars)?this.props.compoundBars:false;

        let data_adapted = compoundBars ? data : Object.keys(data).map(function(entry, i) {
            const the_target = data[entry];

            let the_result = {};
            if (!ignoreMaxContracts && Object.keys(the_target).length > _.get(Settings, "invoices.chart.maxContracts", 10)) {
                the_result = {
                    name: the_target.name,
                    total: the_target.total,
                }
            } else {
                the_result = the_target;
            }

            return the_result;
        });

        // Recalculate 'Total' line for when a bar is hidden
        data_adapted.map((elm, idx) => {
           let newTotal = 0;
           Object.entries(elm).map((component) => {
               let componentName = _.get(component, '[0]',  '');
               if (
                 _.isNull(this.state.enabled_fields) ||
                 !_.isNull(this.state.enabled_fields) && !this.state.enabled_fields[componentName])
               {
                   if (componentName.includes('exportada')) {
                       newTotal -= _.get(component, '[1]', 0);
                   }
                   if (componentName !== 'name' && componentName !== 'total' && !componentName.includes('reactiva')) {
                       newTotal += _.get(component, '[1]', 0);
                   }
               }
           });
           data_adapted[idx].total = String(Math.round(newTotal));
        });
        let components_adapted = {};
        components_adapted = components;
        /*if (!_.get(data[0],'activaP1', false) && Object.keys(data[0]).length > _.get(Settings, "invoices.chart.maxContracts", 10)) {
            components_adapted = {
              'total': { title: 'TOTAL'}
            }
        } else {
          components_adapted = components;
        }*/

        if (data_adapted && components_adapted) {
            /* Aggregations selector
            const areas = prediction.map(function(day, i) {
                return <Area key={"area"+i} type='monotone' dataKey={day.day} stackId={stacked} stroke={colors[i]} fill={colors[i]} />
            });
            //*/

            // At the start, all possible fields are enabled, we first get an array with all the keys
            // Then we create a dictionary with all the keys set to true
            let field_keys = [];
            Object.keys(components_adapted).sort().map(function(component, i) {
                field_keys.push({key: component, value: false});
            });
            let dict_fields = Object.assign({}, ...field_keys.map((x) => ({[x.key]: x.value})));
            dict_fields = Object.assign(dict_fields, {total: false});
            if (!this.state.enabled_fields){
                this.setState({enabled_fields : dict_fields, is_loading : false});
            }

            const unit = (typeof this.props.unit != 'undefined')?this.props.unit:"kWh";

            //avoid showing legend if there are too many elements to show
            let legend = (howManyComponents < 55) ?
            <Legend 
                width={100}
                layout="horizontal" 
                align="center"
                formatter={(value, entry, index) => {
                    return <span style={{color: entry.inactive ? "gray" : "black", fontWeight: "400"}}>{value}</span>
                }}
                wrapperStyle={styles.legend} 
                onClick={ e => this.selectBar(e)}
                onMouseOver={this.handleLegendMouseEnter}
                onMouseOut={this.handleLegendMouseLeave}
            />
            :
            null;
            
            // Define the y and x axis values (the labels shown outside the chart)
            const angle = unit.length > 1? -90: 0;
            // Rotate the label name if its longer than 1 char (€ case)
            const xAxis = <XAxis dataKey="name" label={{ value: group_title }}/>;
            const xAxisLines = <XAxis dataKey="name" angle={-45} height={55} dy={15} label={{ value: group_title, fill: '#666666', paddingTop: 20 }} />;
            const yAxis = <YAxis label={{ value: unit, angle: angle, position: 'insideLeft', fill: '#666666' }} />;
            
            const grid = <CartesianGrid strokeDasharray="3 3"/>;
            const tooltip = <Tooltip content={<CustomTooltip group_title={group_title}/> }/>;

            // Get the fields and the hovered state
            let enabled_fields = this.state.enabled_fields
            let hover = this.state.hover
            
            let brushSlide = <Brush dataKey="name" height={30} stroke={theme.palette.primary.main} />

            // Needs a loading state to not render a null value
            if (!this.state.is_loading && this.state.enabled_fields){
                if (isAreaChart) {
                    // Area chart case
                    if (colors === null) {
                        colors = generateColorList(startingColor, Object.keys(components_adapted).length);
                        this.setState({ colors }); 
                    }

                    const areas = Object.keys(components_adapted).sort().map(function(component, i) {
                        return <Area
                            unit={unit}
                            isAnimationActive={isAnimated}
                            key={"area"+i}
                            type='monotone'
                            dataKey={component}
                            stackId={stacked}
                            stroke={colors[i]}
                            fill={colors[i]}
                        />
                    });

                    return (
                        <div >
                            <ResponsiveContainer height={height} >
                                <AreaChart data={data_adapted}
                                    margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip/>
                                    {areas}
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    );
                }
                else if (lineGraph) {
                    // Line chart case
                    if (colors === null) {
                        colors = generateColorList(startingColor, Object.keys(enabled_fields).length);
                        this.setState({ colors }); 
                    }

                    const lines = Object.keys(enabled_fields)
                    .sort() //sort by key ASC
                    .map(function(component, i) {
                        return <Line
                            unit={unit}
                            isAnimationActive={isAnimated}
                            key={"line"+i}
                            type='monotone'
                            dataKey={component}
                            stackId={stacked}
                            stroke={colors[i]}
                            fill={colors[i]}
                            hide = {enabled_fields[component] === true}
                            strokeOpacity={(hover === component || !hover) ? 1 : 0.5}
                            dot={{ opacity: ((hover === component || !hover) ? 1 : 0.5), strokeOpacity: ((hover === component || !hover) ? 1 : 0.5) }}
                        />
                    });

                    return (
                        <div >
                            <ResponsiveContainer height={height} >
                                <ComposedChart
                                    data={data_adapted}
                                    margin={{top: 20, left: 20}}
                                >
                                    {xAxisLines}
                                    {yAxis}
                                    {grid}
                                    {lines}
                                    {lineGraph}
                                    {legend}
                                    {tooltip}
                                    {brushSlide}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                    );
                }
                else {
                    // Bar chart case
                    if (colors === null) {
                        colors = generateColorList(startingColor, Object.keys(enabled_fields).length);
                        this.setState({ colors }); 
                    }

                    let bars = Object.keys(enabled_fields).map(function(component, i) {
                        if (component !== 'total') {
                            return <Bar
                                legendType = "square"
                                unit={unit}
                                isAnimationActive={isAnimated}
                                key={"area"+i}
                                type='monotone'
                                dataKey={component}
                                stackId={compoundBars ? getStackedId(component) : stacked}
                                stroke={colors[i]}
                                fill={colors[i]}
                                hide = {enabled_fields[component] === true}
                                fillOpacity={(hover === component || !hover) ? 1 : 0.5}
                            />
                        }
                    })

                    // Teníem múltiples línies però tot el que feia això era múltiples 'totals'
                    // const lines = Object.keys(enabled_fields)
                        // .sort() //sort by key ASC
                        // .map(function(component, i) {
                        //     return <Line

                   const lines =
                        <Line
                            unit={unit}
                            isAnimationActive={isAnimated}
                            key={"line"}
                            type='monotone'
                            dataKey={'total'}
                            stackId={stacked}
                            stroke={"black"}
                            fill={"white"}
                            hide = {enabled_fields['total'] === true}
                            strokeOpacity={(hover === 'total' || !hover) ? 1 : 0.5}
                            dot={{ opacity: ((hover === 'total' || !hover) ? 1 : 0.5), strokeOpacity: ((hover === 'total' || !hover) ? 1 : 0.5) }}
                        />;

                    return (
                        <div >
                            <ResponsiveContainer height={height} >
                                <ComposedChart
                                    data={data_adapted}
                                    margin={{top: 20, left: 20}}
                                >
                                    {xAxis}
                                    {yAxis}
                                    {grid}
                                    {bars}
                                    {lines}
                                    {legend}
                                    {tooltip}
                                    {brushSlide}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                    );
                }
            }
        }

        return null;
    }
}

Chart.propTypes = {
    data: PropTypes.array,
    components: PropTypes.object,
    stacked: PropTypes.bool,
    lineGraph: PropTypes.bool,
    areaChart: PropTypes.bool,
    animated: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    unit: PropTypes.string,
    compoundBars: PropTypes.bool,
    group_title: PropTypes.string,
};
