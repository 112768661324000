import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { dispatchNewRoute } from "../../utils/http_functions";
import { i18n } from "../../config";
import * as authActionCreators from '../../actions/auth';
import * as contractsActionCreators from '../../actions/contracts';
import * as invoicesActionCreators from '../../actions/invoices';
import jwt_decode from "jwt-decode";
import { LogoBar } from "../LogoBar";
import { history } from '../../config';

import UILangSelector from "../UILangSelector";
import Settings from "../../settings";
import { retrieve_openid_token } from "../../utils/auth";

import { Button, Divider, MenuItem, AppBar, Toolbar, Menu, Box, Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    userRoles: state.auth.userRoles,
    userGroups: state.auth.userGroups,
    userImage: state.auth.userImage,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      auth: bindActionCreators(authActionCreators, dispatch),
      contracts: bindActionCreators(contractsActionCreators, dispatch),
      invoices: bindActionCreators(invoicesActionCreators, dispatch),
    }
  }
}

class UserProfileButton extends Component {
  state = { open: false, element: null };

  handleMenuOpen = (event) => {
    this.setState({ open: true });
    this.setState({ element: event.currentTarget });
  };

  dispatchRoute(route) {
    dispatchNewRoute(route);
    this.setState({
      open: false,
    });
  }

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ element: null });
  };

  render() {
    const { user, color } = this.props;
    let sub = "";
    if (_.get(Settings, "features.oidc", false)) {
      const oid_token = retrieve_openid_token();
      try {
        const oid_token_values = jwt_decode(oid_token);
        console.log(oid_token_values);
        sub = oid_token_values.sub;
      } catch (exp) {}
    }
    return (
      <>
        <Button
          color={color}
          variant={'contained'}
          onClick={this.handleMenuOpen}
        >
          {i18n.t('common:text.myprofile_title')}
        </Button>
        <Menu
          open={this.state.open}
          anchorEl={this.state.element}
          onClose={this.handleClose}
        >
          <Box
            className="header-horizontal-profile-name"
            title={sub} m={1}
          >
            <MenuItem key={"0"}>
              <div>
                {i18n.t('common:text.user_welcome')} <br />
                <span style={{whiteSpace: "nowrap", fontWeight: "bold"}}>{user.name}</span>
                <br />
                {user.email}
              </div>
            </MenuItem>
          </Box>
          <Divider />
          <Box m={1}>
            {!_.get(Settings, "features.oidc", false) ?
              <MenuItem
                key={"1"}
                value="1"
                onClick={() => {
                  this.dispatchRoute(i18n.t('common:url.changePassword'))
                }}
              >
                {i18n.t('common:text.change_password_view_title')}
              </MenuItem>
              :
              <MenuItem
                key={"1"}
                value="1"
                onClick={() => {
                  window.open(Settings.oidc.changePasswordUrl, "_blank");
                }}
              >{i18n.t('common:text.change_password_title')}</MenuItem>
            }
            {_.get(Settings, "features.CRMcases", false) &&
              <MenuItem
                key={"1"}
                value="1"
                onClick={() => {
                  history.push(i18n.t('common:url.CRM_list'));
                }}
              >{i18n.t('common:text.crm_manage_requests')}</MenuItem>
            }
            <MenuItem
              key={"2"}
              value="2"
              onClick={this.props.logout}
            >{i18n.t('common:text.logout_title')}</MenuItem>
          </Box>
        </Menu>
      </>
    );
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  dispatchRoute(route) {
    dispatchNewRoute(route);
    this.setState({
      open: false,
    });
  }

  logout = (e) => {
    e.preventDefault();
    this.props.actions.auth.logoutAndRedirect();
    this.setState({
      open: false,
    });
  };

  goToInvoices() {
    const token = this.props.token;
    this.props.actions.contracts.clearContractsInvoices();
    this.setState({
      open: false,
    });
    this.props.actions.invoices.fetchInvoices(token, null, false, 0);
  }

  render() {
    const { classes, color } = this.props;
    let current_path = history.location.pathname;
    let user = null;

    // <div className="header-horizontal">
    let noUser = (
      <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
        {(current_path !== i18n.t('common:url.login')) &&
          !_.get(Settings, "features.oidc", false) &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() => this.dispatchRoute(i18n.t('common:url.login'))}
              >
                {i18n.t('common:text.login_title')}
              </Button>
          </Grid>
        }
        {_.get(Settings, "features.signup", false) &&
          !_.get(Settings, "features.oidc", false) &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() => this.dispatchRoute(i18n.t('common:url.activate'))}
              >
                {i18n.t('common:text.login_view_button_activate')}
              </Button>
            </Grid>
        }
        {_.get(Settings, "features.unsignedContact", false) &&
          _.get(Settings, "features.contact", false) &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() => this.dispatchRoute(i18n.t('common:url.unsigned_contact'))}
              >
                {i18n.t('common:text.contact_title')}
              </Button>
            </Grid>
        }
        {(current_path !== i18n.t('common:url.gasPriceComparator')) &&
          _.get(Settings, "comparator.enabled", false) &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() => this.dispatchRoute(i18n.t('common:url.priceComparator'))}
              >
                {i18n.t('common:text.menu_price_comparator_title')}
              </Button>
            </Grid>
        }
        {(current_path !== i18n.t('common:url.gasPriceComparator')) &&
          _.get(Settings, "powerCalculator.enabled", false) &&
            <Grid item>
              <Button
                color={color}
                variant={"contained"}
                onClick={() => this.dispatchRoute(i18n.t('common:url.powerCalculator'))}
              >
                {i18n.t('common:text.menu_power_calculator_title')}
              </Button>
            </Grid>
        }
        <UILangSelector />
      </Grid>
    );

    if (this.props.token) {
      const token_values = jwt_decode(this.props.token);
      user = (
        <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Button
              color={color}
              variant={'contained'}
              onClick={() => this.dispatchRoute(i18n.t('common:url.contracts'))}
            >
              {i18n.t('common:text.contracts_title')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color={color}
              variant={'contained'}
              onClick={() => this.goToInvoices()}
            >
              {i18n.t('common:text.invoices_title')}
            </Button>
          </Grid>
          {_.get(Settings, "features.contact", false) &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() => this.dispatchRoute(i18n.t('common:url.contact'))}
              >
                {i18n.t('common:text.contact_title')}
              </Button>
            </Grid>
          }
          {_.get(Settings, "features.reclamATCMenu", false) &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() => this.dispatchRoute(i18n.t('common:url.reclamations'))}
              >
                {i18n.t('common:text.reclamations_title')}
              </Button>
            </Grid>
          }
          {_.get(Settings, "features.contactAutoReads", false) &&
            <Grid item>
              <Button
                color={color}
                variant={'contained'}
                onClick={() =>
                  this.dispatchRoute(i18n.t('common:url.contact') + '?autolectures=true')
                }
              >
                {i18n.t('common:text.send_readings_label')}
              </Button>
            </Grid>
          }
          <Grid item>
            <UserProfileButton color={color} logout={this.logout} user={token_values} />
          </Grid>
          <Grid item>
            <UILangSelector />
          </Grid>
        </Grid>
      );
    }

    return (
      <header role="banner">
        <div className={classes.root}>
          <AppBar position={'static'}>
            <Toolbar>
              <LogoBar className={classes.title} openLinkInNewTab={false} />
              {this.props.isAuthenticated ? user : noUser}
            </Toolbar>
          </AppBar>
        </div>
      </header>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true })
)(Header);

Header.propTypes = {
  logoutAndRedirect: PropTypes.func,
  isAuthenticated: PropTypes.bool,
};
